<template>
  <div class="main">
    <div class="itemNameBox">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>个人设置</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <el-form class="form" ref="form" :model="form" label-width="150px">
        <div class="myInfo">
          <div class="myInfoLeft">
            <el-form-item label="姓名：" prop="name">
              <el-input :disabled="true" v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="性别：" prop="sex">
              <el-input :disabled="true" v-model="form.sex"></el-input>
            </el-form-item>
            <el-form-item label="所在区域：" prop="area">
              <el-cascader
                v-if="areaFlag"
                v-model="form.area"
                :show-all-levels="false"
                placeholder="请选择城市"
                :props="cityProps"
                @change="handleChangeCity"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="职位类别：" prop="positionType">
              <el-radio-group v-model="form.positionType">
                <el-radio label="1">技术类</el-radio>
                <el-radio label="2">管理类</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="身份证号：" prop="identityCode">
              <el-input :disabled="true" v-model="form.identityCode"></el-input>
            </el-form-item>
          </div>
          <div class="myInfoRight">
            <el-form-item>
              <img
                v-if="userInfo.avatar"
                class="photo"
                :src="userInfo.avatar"
                alt=""
              />
              <img
                v-else
                class="photo"
                src="@/assets/img/noAvatar.png"
                alt=""
              />
              <el-upload
                name="uploadfile"
                class="avatar-uploader"
                :action="$global.uploadAction"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <el-button plain size="small">更换头像</el-button>
              </el-upload>
              <div class="tipBox">建议头像尺寸：最好正方形 200X200 或 300 X 300 宽。<br> 注:<br>
                (1) 上传图片只能是JPG格式或PNG格式,且图片大小不能超过5MB; <br>(2) 上传图片时请检查图片原有格式是否是JPG/PNG格式!</div>

            </el-form-item>
          </div>
        </div>
        <el-form-item label="身份证正面照：" prop="identityCard1">
          <el-upload
            drag
            name="uploadfile"
            :action="$global.uploadAction"
            :before-upload="beforeUpload"
            :on-success="upSuccess1"
            :on-error="upError"
            :on-remove="upRemve"
            :on-change="upChange"
            :show-file-list="false"
            multiple
            class="uploadBox"
          >
            <img
              v-if="form.identityCard1"
              :src="form.identityCard1"
              alt=""
            />
            <img v-else src="@/assets/personCenter/zheng1.png" alt="" />
            <div class="el-upload__tip" slot="tip">
              <span>请上传身份证正面带有头像的清晰图片</span>
              <span>大小:≤5M</span>
              <!-- <span>格式为：.rar .zip .doc .docx .pdf .jpg...</span> -->
              <span>格式为：.jpg .png</span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="身份证反面照：" prop="identityCard0">
          <el-upload
            drag
            name="uploadfile"
            :action="$global.uploadAction"
            :before-upload="beforeUpload"
            :on-success="upSuccess2"
            :on-error="upError"
            :on-remove="upRemve"
            :on-change="upChange"
            :show-file-list="false"
            multiple
            class="uploadBox"
          >
            <img
              v-if="form.identityCard0"
              :src="form.identityCard0"
              alt=""
            />
            <img v-else src="@/assets/personCenter/zheng2.png" alt="" />
            <div class="el-upload__tip" slot="tip">
              <span>请上传身份证反面带有国徽的清晰图片</span>
              <span>大小:≤5M</span>
              <!-- <span>格式为：.rar .zip .doc .docx .pdf .jpg...</span> -->
              <span>格式为：.jpg .png</span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="学历证书：" prop="degreeCertificate">
          <el-upload
            drag
            name="uploadfile"
            :action="$global.uploadAction"
            :before-upload="beforeUpload"
            :on-success="upSuccess3"
            :on-error="upError"
            :on-remove="upRemve"
            :on-change="upChange"
            :show-file-list="false"
            multiple
            class="uploadBox"
          >
            <img
              v-if="form.degreeCertificate"
              :src="form.degreeCertificate"
              alt=""
            />
            <img v-else src="@/assets/personCenter/zheng3.png" alt="" />
            <div class="el-upload__tip" slot="tip">
              <span>请上传学历证书的清晰图片</span>
              <span>大小:≤5M</span>
              <!-- <span>格式为：.rar .zip .doc .docx .pdf .jpg...</span> -->
              <span>格式为：.jpg .png</span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="资格证书：" prop="qualificationCertificate">
          <el-upload
            drag
            name="uploadfile"
            :action="$global.uploadAction"
            :before-upload="beforeUpload"
            :on-success="upSuccess4"
            :on-error="upError"
            :on-remove="upRemve"
            :on-change="upChange"
            :show-file-list="false"
            multiple
            class="uploadBox"
          >
            <img
              v-if="form.qualificationCertificate"
              :src="form.qualificationCertificate"
              alt=""
            />
            <img v-else src="@/assets/personCenter/zheng4.png" alt="" />
            <div class="el-upload__tip" slot="tip">
              <span>请上传资格证书的清晰图片</span>
              <span>大小:≤5M</span>
              <!-- <span>格式为：.rar .zip .doc .docx .pdf .jpg...</span> -->
              <span>格式为：.jpg .png</span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="职称证书：" prop="titleCertificate">
          <el-upload
            drag
            name="uploadfile"
            :action="$global.uploadAction"
            :before-upload="beforeUpload"
            :on-success="upSuccess5"
            :on-error="upError"
            :on-remove="upRemve"
            :on-change="upChange"
            :show-file-list="false"
            multiple
            class="uploadBox"
          >
            <img
              v-if="form.titleCertificate"
              :src="form.titleCertificate"
              alt=""
            />
            <img v-else src="@/assets/personCenter/zheng5.png" alt="" />
            <div class="el-upload__tip" slot="tip">
              <span>请上传职称证书的清晰图片</span>
              <span>大小:≤5M</span>
              <!-- <span>格式为：.rar .zip .doc .docx .pdf .jpg...</span> -->
              <span>格式为：.jpg .png</span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="工作证明：" prop="pow">
          <el-upload
            drag
            name="uploadfile"
            :action="$global.uploadAction"
            :before-upload="beforeUpload"
            :on-success="upSuccess6"
            :on-error="upError"
            :on-remove="upRemve"
            :on-change="upChange"
            :show-file-list="false"
            multiple
            class="uploadBox"
          >
            <img
              v-if="form.pow"
              :src="form.pow"
              alt=""
            />
            <img v-else src="@/assets/personCenter/zheng6.png" alt="" />
            <div class="el-upload__tip" slot="tip">
              <span>请上传工作证明的清晰图片</span>
              <span>大小:≤5M</span>
              <!-- <span>格式为：.rar .zip .doc .docx .pdf .jpg...</span> -->
              <span>格式为：.jpg .png</span>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="btnBox">
        <el-button type="primary" :loading="saveFlag" @click="save"
          >保存</el-button
        >
        <el-button type="info" @click="dialogVisible = true">取消</el-button>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>退出信息将不会保存，是否退出？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="goOutResume">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
import {
  editJobAuthUser,
  editJobUser,
} from '@/api/personalCenter/personalCenter'
import { getLoginUser } from '@/api/login/login'
import { getProvincial, getCity } from '@/api/index/index'
export default {
  name: '',
  data() {
    return {
      imgPath: '',
      dialogVisible: false, //对话框开关
      saveFlag: false, //保存开关，避免多次保存
      imgUrl: '',
      cityProps: {},
      form: {
        name: '', //姓名
        sex: '', //性别
        area: '', //城市
        identityCode: '', //身份证号
        identityCard1: '', //身份证正面
        identityCard0: '', //身份证背面
        degreeCertificate: '', //学历证书
        qualificationCertificate: '', //资格证书
        titleCertificate: '', //职称证书
        pow: '', //工作证明
        jobUserId: '', //用户id
        auditStatus: '', //审核状态
        positionType: '1' // 个人职位类别区分方便评价
      },
      certificates: {}, //认证信息
      userInfo: {}, //个人信息
      cityLit: [],
      areaFlag: false,
    }
  },
  watch: {},
  props: {},
  components: {},
  computed: {},
  created() {
    this.cityProps = {
      value: 'PROVINCE_CODE',
      label: 'SHORT_NAME',
      children: 'children',
      lazy: true,
      lazyLoad: (node, resolve) => {
        const { level } = node
        if (level == 0) {
          getProvincial().then(res => {
            const city = res.data
            resolve(city)
          })
        }
        if (level == 1) {
          getCity({ PROVINCE_CODE: node.value }).then(res => {
            let arr = []
            res.data.forEach(i => {
              const obj = {
                PROVINCE_CODE: i.CITY_CODE,
                SHORT_NAME: i.SHORT_NAME,
                leaf: true,
              }
              arr.push(obj)
            })
            const city = arr
            resolve(city)
          })
        }
      },
    }
    this.cityLit = JSON.parse(localStorage.getItem('cityList'))
    this.getUserInfo()
    this.getJobUserAuthForId()
  },
  mounted() {},
  methods: {
    // 查询认证信息
    getJobUserAuthForId() {
      getJobUserAuth().then(res => {
        if (!res.data) {
          this.areaFlag = true
          return
        }
        console.log(res.data)
        this.certificates = res.data
        this.form.name = this.certificates.name
        this.form.sex = this.certificates.sex == 1 ? '男' : '女'
        // this.form.area = this.certificates.area
        let area = this.cityLit.filter(
          i => i.CITY_CODE == this.certificates.area
        )[0]
        console.log(area, '11111111111111111')
        this.form.area = [area.PROVINCE_CODE, area.CITY_CODE]
        console.log(this.form.area)
        this.areaFlag = true
        // this.form.area = '110100'
        this.form.identityCode = this.certificates.identity_code
        this.form.identityCard1 = this.certificates.identity_card1 ? this.certificates.identity_card1 : null
        this.form.identityCard0 = this.certificates.identity_card0 ? this.certificates.identity_card0 : null
        this.form.degreeCertificate = this.certificates.degree_certificate ? this.certificates.degree_certificate : null
        this.form.qualificationCertificate =
          this.certificates.qualification_certificate ? this.certificates.qualification_certificate : null
        this.form.titleCertificate = this.certificates.title_certificate ? this.certificates.title_certificate : null
        this.form.pow = this.certificates.pow ? this.certificates.pow : null
        this.form.positionType = this.certificates.position_type ? this.certificates.position_type : '1'
        this.form.jobUserId = this.certificates.job_user_id
        this.form.id = this.certificates.id
      })
    },
    // 个人信息
    getUserInfo() {
      getLoginUser().then(res => {
        this.userInfo = res.data
      })
    },
    handleChangeCity(val) {
      console.log(val[val.length - 1])
      // this.form.area = val[val.length - 1]
      this.form.area = val
    },
    // 上传头像成功
    handleAvatarSuccess(res, file) {
      console.log(res, '图片')
      this.userInfo.avatar = file.response.msg
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 .jpg .png 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // 上传文件判断
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 5

      if (!isJPG) {
        this.$message.error('上传图片只能是 .jpg .png 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      return isJPG && isLt2M
      // console.log(file,'file')
      // const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const whiteList = ['rar', 'zip', 'doc', 'docx', 'pdf', 'jpg', 'png']
      // if (whiteList.indexOf(fileSuffix) === -1) {
      //   this.$message({
      //     type: 'error',
      //     message: '上传文件只能是 .rar .zip .doc .docx .pdf .jpg .png... 格式',
      //     showClose: true,
      //     offset: 80,
      //   })
      //   this.fileList = []
      //   return false
      // }
      // const isLt2M = file.size / 1024 / 1024 < 5
      // if (!isLt2M) {
      //   this.$message({
      //     type: 'error',
      //     message: '上传文件不能超过5MB',
      //     showClose: true,
      //     offset: 80,
      //   })
      //   return false
      // }
    },
    // 身份证正面上传
    upSuccess1(res, file) {
      this.form.identityCard1 = file.response.msg
    },
    // 身份背正面上传
    upSuccess2(res, file) {
      this.form.identityCard0 = file.response.msg
    },
    // 学历证书上传
    upSuccess3(res, file) {
      this.form.degreeCertificate = file.response.msg
    },
    // 资格证书上传
    upSuccess4(res, file) {
      this.form.qualificationCertificate = file.response.msg
    },
    // 职称证书上传
    upSuccess5(res, file) {
      this.form.titleCertificate = file.response.msg
    },
    // 工作证明上传
    upSuccess6(res, file) {
      this.form.pow = file.response.msg
    },
    // 上传失败
    upError() {
      this.$message({
        type: 'error',
        message: '上传失败',
        showClose: true,
        offset: 80,
      })
    },
    // 移除列表
    upRemve(file) {
      console.log(file)
    },
    //上传的文件改变时（覆盖原来的文件）
    upChange(file, fileList) {
      // if (fileList.length > 0) {
      //   this.fileList = [fileList[fileList.length - 1]]
      // }
    },
    // 保存个人信息修改
    save() {
      if (this.form.sex == '男') {
        this.form.sex = 1
      } else if (this.form.sex == '女') {
        this.form.sex = 2
      }
      this.form.area = this.form.area[this.form.area.length - 1]
      let remark = ''
      if (this.form.name != this.certificates.name) {
        remark = '姓名'
      }
      if (this.form.sex != this.certificates.sex) {
        if (remark) {
          remark = remark + '、姓别'
        } else {
          remark = '姓别'
        }
      }
      if (this.form.area != this.certificates.area) {
        if (remark) {
          remark = remark + '、区域'
        } else {
          remark = '区域'
        }
      }
      if (this.form.identityCode != this.certificates.identity_code) {
        if (remark) {
          remark = remark + '、身份证号'
        } else {
          remark = '身份证号'
        }
      }
      if (this.form.identityCard1 != this.certificates.identity_card1) {
        if (remark) {
          remark = remark + '、身份证正面附件'
        } else {
          remark = '身份证正面附件'
        }
      }
      if (this.form.identityCard0 != this.certificates.identity_card0) {
        if (remark) {
          remark = remark + '、身份证反面附件'
        } else {
          remark = '身份证反面附件'
        }
      }
      if (this.form.degreeCertificate != this.certificates.degree_certificate) {
        if (remark) {
          remark = remark + '、学历证书附件'
        } else {
          remark = '学历证书附件'
        }
      }
      if (
        this.form.qualificationCertificate !=
        this.certificates.qualification_certificate
      ) {
        if (remark) {
          remark = remark + '、资格证书附件'
        } else {
          remark = '资格证书附件'
        }
      }
      if (this.form.titleCertificate != this.certificates.title_certificate) {
        if (remark) {
          remark = remark + '、职称证书附件'
        } else {
          remark = '职称证书附件'
        }
      }
      if (this.form.pow != this.certificates.pow) {
        if (remark) {
          remark = remark + '、工作证明附件'
        } else {
          remark = '工作证明附件'
        }
      }


      // editJobUser(this.userInfo).then(res => {
      //   console.log(res)
      //   if(res.success){
      //     localStorage.setItem("userImg", this.userInfo.avatar);
      //   }
      // })

      if (remark) {
        this.form.remark = `此次编辑修改了${remark}。`
      } else {
        this.form.remark = ''
      }
      this.form.auditStatus = 4
      // if (this.form.sex == '男') {
      //   this.form.sex = 1
      // } else if (this.form.sex == '女') {
      //   this.form.sex = 2
      // }
      // this.form.area = this.form.area[this.form.area.length - 1]
      // this.form.area = '110100'
      console.log(this.form)
      editJobAuthUser(this.form).then(res => {
        if (res.success) {
          this.$message({
            message: '修改成功',
            type: 'success',
          })
          this.$router.push('personalSettings')
        } else {
          this.$message.error('修改失败')
        }
      })
    },
    // 取消编辑个人信息
    goOutResume() {
      this.$router.push('personalSettings')
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.itemNameBox {
  min-height: 500px;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 60px;
  .itemName {
    padding: 0 86px;
    padding-bottom: 22px;
    .dividerFont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 50px;
      span {
        font-size: 24px;
        margin-bottom: 6px;
      }
    }
  }
}

.tipBox {
  width: 160px;
  font-size: 12px;
  line-height: 18px;
  color: #ccc;
  margin-bottom: 20px;
}

.form {
  padding: 0 75px;
  .myInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .myInfoRight {
      margin-right: 80px;
      .avatar-uploader {
        text-align: center;
      }
      img {
        width: 160px;
        height: 160px;
        object-fit: cover;
      }
    }
  }
}

::v-deep .uploadBox {
  display: flex;
  .el-upload-dragger {
    border: none;
    width: 300px;
    height: 162px;
    img {
      width: 300px;
      height: 162px;
      object-fit: contain;
    }
  }
  .el-upload__tip {
    font-size: 14px;
    color: #999;
    margin-left: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    span {
      margin-top: 10px;
      line-height: normal;
    }
  }
}

.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  .el-button {
    width: 83px;
    height: 42px;
    border-radius: 2px;
    margin: 0 60px;
  }
}
</style>
